<template>
  <div class="preview-equipment-box" v-if="visible">
    <div class="preview-head">
      <div class="equipment">
        <div class="equipment-item" :class="{'active': equipment === 1}" @click="equipment = 1">
          <icon-font class="mr-1" name="#fanhan-shouji" :width="36" :height="36"></icon-font>
          <span>手机预览</span>
        </div>
        <div class="equipment-item ml-4" :class="{'active': equipment === 0}" @click="equipment = 0">
          <icon-font class="mr-2" name="#fanhan-diannao" :width="36" :height="36"></icon-font>
          <span>电脑预览</span>
        </div>
      </div>
      <el-button class="preview-equipment-btn" @click="exit">结束预览</el-button>
    </div>
    <div ref="preview" class="preview-wrap">
      <div class="pc" v-show="equipment === 0">
        <div class="title">{{title}}</div>
        <div v-html="formatContent"></div>
      </div>
      <div class="phone" v-show="equipment === 1">
        <div class="phone-box">
          <div class="title">{{title}}</div>
          <div v-html="formatContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'preview-equipment',
  props: {
    visible: {
      typeof: Boolean,
      default: true
    },
    content: String,
    title: String
  },
  watch: {
    content (n, o) {
      if (n) {
        const reg = /url\(&quot;(.*)&quot;\)/g
        let tempR = reg.exec(this.content)
        while (tempR) {
          const url = tempR[1]
          const img = new Image()
          img.src = url
          img.referrer = 'no-referrer'
          img.onload = () => {
            console.log(url, 'finish')
          }
          tempR = reg.exec(this.content)
        }
      }
    }
  },
  computed: {
    formatContent () {
      const rep = /\n+/g
      const repone = /[ ]+</ig
      const sourceZero = this.content.replace(rep, '')
      const sourceOne = sourceZero.replace(repone, '<')
      return sourceOne
    }
  },
  data () {
    return {
      equipment: 0
    }
  },
  methods: {
    curExec (reg) {
      const curReg = reg
      return (content) => {
        const has = curReg.exec(content)
        if (has.length > 0) {
          return has[1].trim()
        } else {
          return false
        }
      }
    },
    exit () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="less" scoped>
.preview-equipment-box {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F1F1F1;
  z-index: 1050;
  .title {
    margin-bottom: .5em;
    font-weight: 700;
    color: #555555;
  }
  .pc .title {
    font-size: 28px;
  }
  .phone .title {
    font-size: 26px;
  }
  /deep/ * {
    max-width: 100%;
    box-sizing: border-box !important;
  }
  /deep/img {
    max-width: 100%;
    height: auto !important;
  }
  /deep/p {
    margin-bottom: 0;
  }
  .preview-head {
    position: relative;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 48px;
    background: #fff;
  }
  .equipment {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #333333;
    .equipment-item {
      cursor: pointer;
      &.active {
        color: #3775F6;
      }
    }
  }
  .preview-wrap {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    // display: flex;
    // justify-content: center;
    .pc {
      position: absolute;
      top: 24px;
      left: 50%;
      // min-height: 100%;
      width: 960px;
      // margin: 24px auto 0 auto;
      // margin-top: 24px;
      padding: 48px 180px;
      background: #fff;
      transform: translateX(-50%);
    }
    .phone {
      position: absolute;
      width: 359px;
      height: 678px;
      left: 50%;
      margin-top: 45px;
      margin-left: 70px;
      padding: 20px 62px 62px 22px;
      background: url('../assets/img/phone.png') no-repeat;
      background-size: 359px 678px;
      transform: translateX(-50%);
      .phone-box {
        background: #fff;
        height: 100%;
        width: 100%;
        padding: 54px 16px;
        border-radius: 30px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }
}
.preview-equipment-btn {
  padding: 8px 12px;
  border: 1px solid #999999;
  border-radius: 2px;
}
</style>
